<template>
  <h1 class="h4 mb-0">Timeline </h1>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div id="tracking">
        <div class="tracking-list">
          <TimelineItem 
            v-if="dataLoaded" 
            :timelines="timeline.logs" 
            @share-button-clicked="handleShareButtonClick" 
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" :id="'sharelink'"  tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <h5>Shareable Link</h5>
                    <p class="small" id="sharelink">{{ shareLink }}</p> 
                    <div class="copy-btn"><button class="btn btn-primary" @click="copyToClipboard">{{ copyToClipboardBtnText }}</button></div>
                </div>
              <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onModalClose">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style scoped lang="scss">
  .status {
    margin-bottom: 10px;;
  }
  #tracking {
    margin-bottom:1rem;
    margin-top:1rem;
    padding: 2rem 0;
    border: 2px solid #EEEEEE;
    border-radius: 0.375rem;
  }
  .modal-dialog {
    word-break: break-all;
  }
  .copy-btn {
    text-align: center;
  }
</style>
  
<script>
  import TimelineItem from "./TimelineItem.vue";
  import api from "@/services/api";
  import { Modal } from 'bootstrap';
      export default {
        emits: ['hasTimeline'],
          components: {     
            TimelineItem,       
          },
          data() {
              return {
                  dataLoaded: false,
                  orderData: null,
                  timeline: {},
                  shareLink: '',
                  copyToClipboardBtnText: 'Copy to Clipboard'
              }
          },
          methods: {
            async copyToClipboard() {
              try {
                if (!navigator.clipboard) {
                  throw new Error('Clipboard API not available');
                }
                await navigator.clipboard.writeText(this.shareLink);
                this.copyToClipboardBtnText = 'Copied!';
              } catch (err) {
                console.error('Failed to copy text: ', err);
              }
            },
            onModalClose() {
              this.copyToClipboardBtnText = 'Copy to Clipboard';
            },
            handleShareButtonClick(url) {
              this.shareLink = url;
              new Modal(document.getElementById('sharelink')).show();
            },
              fetchTimeline: function() {
                  if(!this.$store.state.currentPracticeId)
                  {
                      this.orderData = null;
                      return;
                  } 
                  var apiRequests = [
                      api.get(`/api/v1/orders/${this.$route.params.id}/timeline`)
                  ];
                  Promise.all(apiRequests).then((responses) => {
                    this.timeline = responses[0].data.data;
                    this.dataLoaded = true;

                    if (this.timeline.logs.length > 0) {
                        this.$emit('hasTimeline', true);
                    }
                  })
                  .catch((error) => {
                      console.error('An error occurred:', error);
                  });
              }
          },
          mounted () {
              this.fetchTimeline();
          },
    }
</script>