<template>

    <div v-if="consignmentData">
        <PageSection>
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h1 class="h4 mb-0">Track delivery</h1>
                </div>
                <div v-if="consignmentData.tracking_url">
                    <a :href="consignmentData.tracking_url" target="_blank" class="btn btn-sm btn-secondary" to="/orders/create">Track delivery</a>
                </div>
            </div>
            <div><strong>Dispatched:</strong> {{$filters.formatDate(consignmentData.dispatch_date)}}</div>
            <div><strong>Expected:</strong> {{$filters.formatDate(consignmentData.expected_date)}}</div>
            <div><strong>Delivered by:</strong> {{consignmentData.delivery_company}}</div>
        </PageSection>
        <PageSection>
            <PageSectionHeading>Orders</PageSectionHeading>
            <ul class="order-list">
                <li v-for="order of consignmentData.orders" v-bind:key="order.id" class="pb-2">
                    <div class="row align-items-center">
                        <div class="col">
                            {{ order.patient_name }}
                        </div>
                        <div class="col">
                            {{ order.dentist.first_name }} {{ order.dentist.surname }}
                        </div>
                        <div class="col col-auto">
                            <router-link class="btn btn-secondary btn-sm" :to="{ name: 'Order', params: { id: order.id }}">View</router-link>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-if="!consignmentData.orders.length">
                No Orders found for this consignment
            </div>
        </PageSection>
    </div>

</template>

<style scoped lang="scss">
    .order-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }
    }
</style>

<script>
    import api from "@/services/api";
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';

    export default {
        props: {
            id: {
                required: true
            }
        },
        components: {            
            PageSection,
            PageSectionHeading,
        },
        data() {
            return {
                consignmentData: null,
            };
        },
        mounted () {
            this.fetchConsignmentData();
        },
        methods: {
            fetchConsignmentData: function() {                
                api
                .get('/api/v1/consignments/' + this.id, {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                    }
                })
                .then((response) => {
                    this.consignmentData = response.data.data;
                })
                .catch(() => {
                    console.log('Catch order error');
                });
            },
        },
    }

</script>
