<template>
            <PageSectionHeading>Tooth Annotations</PageSectionHeading>
            <div class="teeth-chart">  
                <div v-for="(tooth, toothIndex) of alignerDetailData.teeth_chart" v-bind:key="toothIndex">
                  <strong>{{tooth.tooth_number.toUpperCase()}}:</strong> {{ tooth.tooth_state }} {{tooth.comment ? ' - ' + tooth.comment : ''}}
                </div>
            </div>

</template>


<script>
 import { Modal } from 'bootstrap';
 import PageSectionHeading from '@/components/PageSectionHeading.vue';
    export default {
        props: {
            alignerDetailData: {
                required: true
            }
        },
        data() {
            return {
                toothNumber: '',
                toothComment: '',
                toothState: '',
            };
        },
        components: {
            PageSectionHeading,
        },
        methods: {
            showAnnotation: function (tooth) {
                this.toothComment = tooth.comment;
                this.toothState = tooth.tooth_state;
                this.toothNumber = tooth.tooth_number;
                new Modal(document.getElementById('tooth-modal')).show();
            },
        }
    }
</script>

<style scoped lang="scss">
.teeth-chart {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    .tooth {
        background-color: #eee;
        padding-top:6px;
        cursor:pointer;
        display:flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        margin-right: 10px;
        &:hover {
            background-color: #ddd;
        }
    }
    .tooth-number {
        color:#fff;
        background-color: #666;
        align-self:flex-end;
        width:100%;
    }
    img {
        flex-grow:1;
    }
}   

</style>