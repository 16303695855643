<template>
  <div>
    <div>
    <button class="upload-btn btn-primary btn me-1" @click="triggerFileInput">{{ label }}</button>
    </div>
    <input type="file" ref="fileInput" class="btn btn-primary me-1" @change="filesChange($event.target.files)" style="display: none" multiple />
    <div class="form-text" v-for="(unprocessedFile, index) of unprocessedFiles" v-bind:key="index">
        {{unprocessedFile.file.name}}: {{unprocessedFile.message}}

        
    </div>
   
        <div v-for="processingFile of allFiles" v-bind:key="processingFile.uploadId">
            <div class="mt-1">
                <div class="d-flex justify-content-between mb-1 progress-wrapper">
                    <span class="text-truncate">{{ processingFile.name }}</span>
                    <span v-html="calculateUploaded(processingFile, false)"></span>
                    <div class="progress-bar" role="progressbar" :style="{ width: calculateUploaded(processingFile,true) }"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .progress-wrapper {
        position:relative;
        font-size:18px;
        padding-right: 5px;
        .text-truncate {
            padding-left:8px;
        }
        .progress-bar {
            position: absolute;
            height: 100%;
            background: rgba(0, 0, 0, .1);
            border-radius: 5px;
            transition:all 0.3s;
        }
        .tick {
            padding-right: 6px;
        }
        span {
            text-align: left;
            color: #000;
        }
       
    }

    .upload-btn {
        width:100%;   
    }

</style>

<script>
import FileUpload from "@/services/FileUpload";
    export default {
        data() {
            return {
                processingFiles: [],
                uploadedFiles: [],
                unprocessedFiles: [],
                isUploadStarted: false,
            }
        },
        props: {
            label: {
                type: String,
                required: true
            },
            orderId: {
                type: Number,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            uploadFiles: {
                type: Boolean,
                required: true
            },
            modelValue: {
                type: Boolean,
                required: true
            },
            allowedExtensions: {
                type: Array,
                required: true
            }, 
            resetFiles: {
                type: Boolean,
                required: true
            }
        },
    mounted() {
        this.checkAllowedExtensions();
    },
    computed: {
        allFiles () {
            var processingFiles = this.processingFiles.filter(processingFile => !processingFile.processed)
            var uploadedFiles = this.uploadedFiles;
            return uploadedFiles.concat(processingFiles);
        },
        processedFiles() {
            return this.allFiles.filter(processingFile => processingFile.processed);
        },
    },
    watch: {
        uploadFiles(upload) {
            if (upload) {
                this.submit();
            }
        },
        resetFiles(submitted) {
            if (submitted) {
                 this.uploadedFiles = [];
                 this.processingFiles = [];
                 this.unprocessedFiles = [];
            }
        }
    },
    methods: {
        checkAllowedExtensions: function() {
            var missingExtensions = this.allowedExtensions.filter((extension) =>  {
                return FileUpload.getMimetype(extension) === null;
            });

            if(missingExtensions.length)
            {
                alert('Missing mimetype for ' + missingExtensions);
            }
        },
        resetFilesArray: function() {
            this.allFiles.splice(0, this.allFiles.length);
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        filesChange: function (fileList) {
            if (!fileList.length) {
                return;
            }
            this.unprocessedFiles = [];
            
            Object.values(fileList).forEach(file => {
                this.processFile(file);
            });

        },
        processFile: function (file) {
            var fileExtension = FileUpload.getExtension(file);
            var mimetype = FileUpload.getMimetype(fileExtension);

            if(fileExtension === null || !this.allowedExtensions.includes(fileExtension))
            {
                this.unprocessedFiles.push({
                    file: file,
                    message: 'filetype not allowed',
                });

                return false;
            }
            FileUpload.startUpload(file.name, file, mimetype)
            .then((uploadData) => {
                var processingFile = this.getNewProcessingFile(file, uploadData);
                this.processingFiles.push(processingFile) - 1;
                this.$emit('show-submit');
            })
            .catch((error) => {
                this.unprocessedFiles.push({
                    file: file,
                    message: typeof error.message !== 'undefined' ? error.message : null,
                });
            });
        },
        submit: function () {
            if(!this.processingFiles.length) {
                this.$emit('update:modelValue', true);
            }
            this.processingFiles.forEach((processingFile, index) => {
                if (!processingFile.processed) {
                    this.upload(processingFile, index);
                }
            });
        },
        getNewProcessingFile: function(file, uploadData) {
            uploadData.orderId = this.orderId;
            uploadData.type = this.type;
            return {
                name: file.name,
                hash: null,
                processed: false,
                file: file,
                uploadData: uploadData,
            };
        },
        upload: function (processingFile, fileIndex) {
            
            FileUpload.uploadParts(this.processingFiles[fileIndex].uploadData) // Must remain as this.processingFiles. Using processingFile instead breaks onUploadProgress
            .then(() => {
                this.completeUpload(processingFile);
            });
        },
        completeUpload: function(processingFile)
        {
            
            FileUpload.completeUpload(processingFile.uploadData)
            .then((fileHash) => {
                processingFile.hash = fileHash;
                processingFile.processed = true;

                this.uploadedFiles.push(processingFile);

                if(this.uploadedFiles.length === this.processingFiles.length) {
                    this.$emit('update:modelValue', true);
                }

            })
            .catch(() => {
                
            });
            
        },
        calculateUploaded: function(processingFile, forBar) {
            if(!forBar) {
            if (processingFile.processed) {
                return '<span><i class="bi-check2 navbar-icon"></i></span>';
            }

            } else {
            if (processingFile.processed) {
                return '100%';
            }
            }

            var  uploadedBytes =  processingFile.uploadData.parts.map(part => part.uploadedBytes).reduce((partialSum, a) => partialSum + a, 0);
            this.isUploadStarted = true;

            if(Math.ceil((uploadedBytes / processingFile.file.size) * 100) > 0) {
                return Math.ceil((uploadedBytes / processingFile.file.size) * 100) + '%';
            }
        },
  }
};
</script>

<style scoped>
/* Add any styles if necessary */
</style>