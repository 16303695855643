<template>
  <div class="modal fade" id="decision-comment-modal"  tabindex="-1" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="row p-4">
          <div class=" text-center">
            <span v-if="actionId != 3">Are you sure you want to <br />{{selectedAction}} this treatment plan ?</span>
          </div>
          <div class="col-md-12" v-if="!hideComment">
            <label v-if="actionId === 3" class="form-label">Modifications Required</label>
            <textarea rows="3" class="form-control" v-model="comment" :class="{ 'is-invalid': v$.comment.$error }" ></textarea>
            <div class="invalid-feedback" v-if="v$.comment.$error">A value is required</div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button v-if="actionId === 1" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="onModalSave">Accept Plan</button>
          <button v-if="actionId === 3" type="button" class="btn btn-primary" @click="onModalSave">Request Adjustments</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onModalClose">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from "bootstrap";
import api from "@/services/api";
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {
  data() {
    return {
      actionId: null,
      selectedAction: '',
      comment: '',
      treatmentPlanId: null,
      modal: null,
      hideComment: false,
      ACCEPT: 1,
      MODIFY: 3,
    }
  },
  validations() {
    return {
      comment: { 
        requiredIf: requiredIf(() => !this.hideComment)
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
  },
  methods: {
    showModal(action,treatmentPlanId){
      //Yeah I know this is bad
      this.actionId = action;
      if(action === 1){
        this.hideComment = true;
        this.selectedAction = 'accept'
      } else if(action === 3) {
        this.hideComment = false;
        this.selectedAction = 'modify'
      }
      this.treatmentPlanId = treatmentPlanId;

      this.modal = new Modal(document.getElementById('decision-comment-modal'));
      this.modal.show();
    },
    async onModalSave() {
      this.v$.$touch();
      if (this.v$.$pending) {
          await this.v$.$validate();
      }
      if (this.v$.$invalid) {
          return;
      }
      this.modal.hide();
      var that = this;
      api.post(`/api/v1/aligners/${this.$route.params.id}/treatment-plan/decision`, {
        decision: this.actionId,
        treatment_plan_id: this.treatmentPlanId,
        comment: this.comment ? this.comment : '',
      }).then(function (){
        that.hideButtons();
        that.onModalClose();
      });
    },
    onModalClose(){
      this.modal.hide();
    },
    hideButtons(){
     var modalButtons = document.getElementsByClassName('modal-button');
     for(var i = 0; i < modalButtons.length; i++){
       modalButtons[i].classList.add('hide');
     }
    },
  }
}
</script>